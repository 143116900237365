<template>
  <!-- DOCUMENTS FROM TOPIC NAV -->
  <div v-if="userRole != 'Analyst' || fromTopic ">
    <div class="card">
      <DataTable
        :value="documents"
        :loading="loading"
        responsiveLayout="scroll"
        showGridlines
        :paginator="true"
        
        :rows="rowsPagValue"
        :rowsPerPageOptions="[25, 50]"
        :rowHover="true"
        :first="firstPagValue"
        
        removableSort
        dataKey="id"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="menu"
        :sort-field="sortFieldValue"
        :sort-order="sortOrderValue"
        @sort="onSort($event)"
        @page="onSort($event)"
      >
        <template #header>
          <!-- MainTitle -->
          <div v-if="selectedTopic">
            <div v-if="fromTopic" style="display: flex; align-items: center;" >
              <!-- url helper -->
              <h5 style="margin-bottom: 0;" >Documents for  {{selectedTopic.name}}</h5>
              <Button
                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('documentsOverview-header')"
              />
            </div>
            
            <div v-else style="display: flex; align-items: center; ">
              <h5 style="margin-bottom: 0;" >Documents</h5>
              <!-- <Button
                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('documentsOverview-header')"
              /> -->
            </div>
          </div>
          <div v-else style="display: flex; align-items: center; ">
            <h5 style="margin-bottom: 0;" >Documents</h5>
            <!-- <Button
              class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
              icon="pi pi-question-circle" 
              @click="goToHeaderPage('documentsOverview-header')"
            /> -->
          </div>
          <!-- Buttons Header -->
          <div
            class="table-header row gy-3 d-flex flex-row justify-content-between"
          >
            <!-- AddDocumentButton -->
            <div
              class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start"
            >
              <Button
                type="button"
                icon="pi pi-plus"
                label="Add"
                class="p-button-outlined"
                @click="openAddDocument()"
              />
            </div>
            
            <!-- ClearButton And KeyWord Search -->
            <div class="col-sm-12 col-md-8">
              <div class="d-flex flex-row justify-content-md-end">
                <!-- clear Filter -->
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Clear"
                  class="p-button-outlined me-4"
                  
                  @click="clearFilter()"
                />
                <!-- style="width: 50%" -->

                <!-- KEYWORDSEARCH -->
                <span class="p-input-icon-left" style="position: relative;">
                  <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Keyword Search"
                    class="keywordSearch me-4"
                  />
                </span>

                <!-- FilterDocumentType -->
                <Dropdown
                  v-model="filterDocumentSourceType"
                  :options="documentSources"
                  optionLabel="name"
                  optionValue="documentSourceId"
                  placeholder="Filter Document Type"
                  :showClear="true"
                  class="me-4"
                  style="width:25%;"
                  :filter="true"
                >
                </Dropdown>

                <!-- TODO: COUNTRY FILTER  FILTER BY ABREVIATION AND NAME --> 
                <span class="p-input-icon-left" style="position: relative;">
                  <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                  <Dropdown
                    v-model="countrySearch"
                    :options="countries"
                    optionLabel="displayName"
                    optionValue="name"
                    placeholder="Filter Country"
                    :showClear="true"
                    class="me-4"
                    style="width:90%;"
                    :filter="true"
                  >
                  </Dropdown>


                  <!-- <InputText 
                    v-model="countrySearch" 
                    placeholder="Search Country..." 
                    class="keywordSearch me-4"  
                  /> -->
                </span>

                <!-- COMPANYFILTER -->
                <span class="p-input-icon-left" style="position: relative;">
                  <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                  <InputText 
                    v-model="companySearch" 
                    placeholder="Search Companies..." 
                    class="keywordSearch" 
                  />
                </span>


              <!-- FILTERAPPLICATION -->
                <!-- Hide Application feat usability 11-01-2024 -->
                <!-- <Dropdown
                  v-model="filterApplication" 
                  :options="documentApplicationsFilter" 
                  optionLabel="name" 
                  optionGroupLabel="label" 
                  optionGroupChildren="items" 
                  option-value="applicationTypeId"
                  placeholder="Filter Application" 
                  class="me-4"
                  :showClear="true"
                  style="width:25%;"
                >
                  <template #optiongroup="slotProps">
                      <div v-if="!slotProps.option.label" class="flex align-items-center"  >
                        <hr>
                      </div>
                      <div v-else  >
                      </div>
                  </template>
                </Dropdown> -->

                
              </div>
            </div>
          </div>
        </template>
        <!-- Document Title -->
        <Column
          :field="'title'"
          :header="'Title'"
          :key="'title'"
          :sortable="true"
          style="min-width: 35rem"
        >
          <template #body="slotProps">
            <div
              @click="openEditDocumentDialog(slotProps.data)"
              :style="{'width': '100%', 'height': '100%', 'cursor': 'pointer'}"
            >
              {{ slotProps.data.title }}
            </div>
          </template>
        </Column>
        <!-- dateAdded -->
        <Column
          :field="'dateAdded'"
          :header="'Document Added'"
          :key="'dateAdded'"
          bodyStyle="text-align: center"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.dateAdded)  }}
          </template>
        </Column>
        <!-- document Date -->
        <Column
          :field="'documentDate'"
          :header="'Document Date'"
          :key="'documentDate'"
          :sortable="true"
          bodyStyle="text-align: center"
        >
          <template #body="slotProps" style="display:flex; justify-content: center; align-items: center;">
            {{ formatDate(slotProps.data.documentDate) }}
          </template>
        </Column>
        <!-- documentSourceTypeId -->
        <Column
          :field="'documentSourceTypeId'"
          :header="'Document Type'"
          :key="'documentSourceTypeId'"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ getDocumentSourceTypeName(slotProps.data.documentSourceTypeId)  }}

          </template>
        </Column>
        <!-- Program -->
        <Column
          :field="'Program'"
          :header="'Programme'"
          :key="'program'"
        >
          <template #body="slotProps">
            {{ getProgramsName(slotProps.data.documentTopics) }}
          </template>
        </Column>
        <!-- Application  -->
        <!-- Hide Application feat usability 11-01-2024 -->
        <!-- <Column 
          :field="'applications'" 
          :header="'Applications'" 
          :key="'applications'" 
          :sortable="false"
        >
          <template #body="slotProps">
            <div>
              <span 
                v-tooltip="application.name" 
                class="mx-2" 
                v-for="application of getApplicationNameById(
                slotProps.data.applications
                )" 
                :key="application.applicationTypeId"
              >
                <span v-html="application.icon"></span>
                <font-awesome-icon :icon="[application.prefix, application.iconName]" /> 
              </span>
            </div>
          </template>
        </Column> -->

        <!-- Countries  -->
        <Column
          :field="'documentCountries'"
          :header="'Countries'"
          :key="'documentCountries'"
        >
          <template #body="slotProps">
            {{ getDocumentCountriesAbreviation(slotProps.data.documentCountries)  }}
          </template>
        </Column>

        <!-- Companies -->
        <Column
          :field="'documentCompanies'"
          :header="'Companies'"
          :key="'documentCompanies'"
        >
          <template #body="slotProps">
            {{ getDocumentCompaniesNameAbreviation(slotProps.data.documentCompanies)}}
          </template>
        </Column>

        <!-- PreviewDocumentDialog -->
        <Column 
          :exportable="false" 
          style="min-width: 5rem"
        >
          <template #body="slotProps">
            <div style="display: flex; ">
                <div>
                  <div v-if="slotProps.data.text">
                    <Button
                      icon="pi pi-eye"
                      class="p-button-rounded p-button-success mx-2"
                      @click="openPreviewDocumentDialog(slotProps.data)"
                      @mouseenter="hoverPreviewDocumentDialogIn(slotProps.data)"
                      @mouseleave="hoverPreviewDocumentDialogOut"
                    />
                  </div>
                  <div v-else>
                    <Button
                      icon="pi pi-eye"
                      class="p-button-rounded p-button-success mx-2"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div>
                  <div v-if="slotProps.data.contract">
                    <Button
                      icon="pi pi-file"
                      class="p-button-rounded p-button-success mx-2"
                      @click="openPreviewDocumentContracDialig(slotProps.data)"
                      @mouseenter="hoverPreviewDocumentContractDialogIn(slotProps.data)"
                      @mouseleave="hoverPreviewDocumentContractDialogOut"
                    >
                      <font-awesome-icon icon="fa-solid fa-file-contract" />
                    </Button>
                  </div>
                  <div v-else>
                    <Button
                      icon="pi pi-file"
                      class="p-button-rounded p-button-success mx-2"
                      :disabled="true"
                    >
                      <font-awesome-icon icon="fa-solid fa-file-contract" />
                    </Button>
                  </div>
                </div>

                <div>
                  <div v-if="slotProps.data.filePublicUrl">
                    <Button
                      icon="pi pi-file-pdf"
                      class="p-button-rounded p-button-success mx-2"
                      @click="openPreviewDocumentFileDialig(slotProps.data)"
                    >
                    </Button>
                  </div>
                  <div v-else>
                    <Button
                      icon="pi pi-file-pdf"
                      class="p-button-rounded p-button-success mx-2"
                      :disabled="true"
                    >
                    </Button>
                  </div>

                </div>

            </div>
          </template>
        </Column>
        <!-- SelectMultiple -->
        <Column
          style="width: 5rem;"
          bodyStyle="text-align: center"
        >
          <template #header>
            <div v-if="deleteMultipleDocuments.length > 0">
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="openDeleteMultipleDocumentsDialog()"
              />
            </div>
          </template>
          <template #body="slotProps">
            <div>
              <Checkbox :inputId="slotProps.data.documentId" :value="slotProps.data.documentId" name="city" v-model="deleteMultipleDocuments" />
            </div>
          </template>
        </Column>
        <template #paginatorstart>
          <Button
            type="button"
            icon="pi pi-refresh"
            class="p-button-text"
            @click="getDocuments()"
          />
        </template>
      </DataTable>
    </div>
  </div>
  
  <div v-else>
    <h1 style="color: red;"> You are not authorized</h1>
  </div>

  <!-- add Document -->
  <Dialog
    v-model:visible="addDocument"
    :style="{ width: '80%' }"
    header="Website url"
    :modal="true"
  >
    <div style="display:flex; flex-direction: column; gap: 1rem;">
      <DocumentCrawlerVue 
        @getDocumentDataApifyWebsiteContentCrawler="getDocumentDataApifyWebsiteContentCrawler" 
        @getDocumentDataApifySmartArticleExtractor="getDocumentDataApifySmartArticleExtractor" 
      />
      <div 
        style="display: flex; 
        justify-content: center;"
      >
        <Button 
          label="Add Document manually" 
          class="p-button-text" 
          @click="openCreateDocumentDialog"
        />
      </div>
    </div>
  </Dialog>

  <!-- Delete Multiple Documents -->
  <Dialog
    v-model:visible="deleteMultipleDocumentDialog"
    :style="{ width: '450px' }"
    header="Delete Multiple Documents"
    :modal="true"
  >
    <div class="confirmation-content d-flex flex-row">
      <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
      <span
        >Are you sure you want to delete {{ deleteMultipleDocuments.length }} documents 
      </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog()"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        :loading="deleteDocumentIsLoading"
        :disabled="deleteDocumentIsLoading"
        @click="deleteDocuments()"
      />
    </template>
  </Dialog>
  <!-- Preview Document  -->
  <Dialog
    v-model:visible= "previewDocumentDialog"
    :style="{width: '80%'}"
    :modal="true"
  >
    <template #header>
      <h5> Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
    </template>
    <div v-html="selectedRowDocument.text" ></div>
  </Dialog>
  <!-- Preview Document Hover -->
  <div 
    class="modalPreview"
    v-show="previewHoverDocumentDialog"
  >
    <div class="modalContainer">
        <div v-if="selectedRowDocument">
          <h5>Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
          <div v-html="selectedRowDocument.text" class="modalContainer-Text" ></div>
        </div>
        <div v-else>
          loading
        </div>
    </div>
  </div>
  <!-- Preview DocumentContract  -->
  <Dialog
    v-model:visible= "previewDocumentContractDialog"
    :style="{width: '80%'}"
    :modal="true"
  >
    <template #header>
      <h5> Contract for:  {{selectedRowDocumentContract.title  }}</h5>
    </template>
    <div>
      {{selectedRowDocumentContract.contract  }}  
    </div>
  </Dialog>
  <!-- preview DocumentContract Hover  -->
  <div 
      class="modalPreview"
      v-show="previewHoverDocumentContractDialog"
    >
      <div class="modalContainer">
          <div v-if="selectedRowDocumentContract">
            <h5>Contract <strong>{{selectedRowDocumentContract.title }} </strong> </h5> 
            <div>
              {{selectedRowDocumentContract.contract }}
            </div>
          </div>
          <div v-else>
            loading
          </div>
      </div>
  </div>
  
</template>

<script>

import formatToGlobalDate from '@/shared/helpers/date.helper';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import redirectHelperPage from '../../../../shared/helpers/redirectToHelperPage';

// components
import DocumentCrawlerVue from '../../../../components/DocumentCrawler.vue';
// createformData
import { createFormData } from '@/shared/helpers/formData.helper';
import {createDocument} from '../../document/services/document.service'

const toast = useToast();

export default {
  name: 'DocumentsList',
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    Checkbox,
    Dropdown,
    DocumentCrawlerVue,
  },
  props: ['fromTopic'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: null,
      loading: true,

      selectedRowDocument: null,
      selectedRowDocumentContract: null, 
      
      deleteDocumentIsLoading: false,
      deleteMultipleDocumentDialog:false,

      // addDocument Crawler
      addDocument : false, 

      // Preview Documents 
      previewDocumentDialog:false,
      previewHoverDocumentDialog:false,

      previewDocumentContractDialog: false,
      previewHoverDocumentContractDialog:false, 

      // delete MultipleDocuments  
      deleteMultipleDocuments: [],

      // FilterApplication
      filterApplication: null,

      //filterDocumentSourceType
      filterDocumentSourceType : null,

      // sortValues
      sortFieldValue: null,
      sortOrderValue: null,

      //paginationCountValues
      firstPagValue: null,
      rowsPagValue: 25,
      //FIlter countries
      countrySearch: null,
      // Filter Companies
      companySearch: null,
      
    };
  },
  validations() {
    return {
    };
  },
  created() {
    this.initFilters();
  },

  async mounted() {
    await this.getCountries();
    await this.getDocuments();
    await this.getDocumentApplications();
    await this.getDocumentSources();
    if(!this.fromTopic){
      await this.$store.dispatch('cleanSelectedTopic');
    }
    this.setDocumentSort();
    // console.log(this.countries);
  },

  computed: {
    userRole() {
      return this.$store.getters.obtainCurrentUserRole;
    },
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    countries() {
      const countries = this.$store.getters.obtainCountries;
      
      // Asignar el resultado de map a una nueva variable o a 'countries'
      const mappedCountries = countries.map(country => {
        return {
          ...country,
          displayName: `${country.name} (${country.countryCode})`
        };
      });

      // Ordenar los países por nombre
      let sortedCountries = mappedCountries.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      // Agregar el objeto 'Without Country'
      const withoutCountry = {
        "countryId": null,
        "countryCode": null,
        "name": "Without Country",
        "information": null,
        "inventory": null,
        "totalDocuments": null,
        "totalPrograms": null,
        "hasPrioritary": false,
        "regionCountries": [],
        "displayName": `Without Country`
      };

      // sortedCountries.push(withoutCountry);

      // console.log(sortedCountries);
      
      // return sortedCountries;


      return [...sortedCountries, withoutCountry];
    },


    documents() {
      let doc = this.$store.getters.obtainDocuments 
      if(this.filterApplication){
        if(this.filterApplication === 'applicationNotSet'){
          doc =  doc.filter(d=> d.applications.length === 0 )
        }
        else{
          doc =  doc.filter(d=> d.applications.includes(this.filterApplication) )
        }
      }
      if(this.filterDocumentSourceType){
        doc = doc.filter(d=> d.documentSourceTypeId === this.filterDocumentSourceType)
      }
      // FILTER COUNTRY NAME
      if(this.countrySearch){
          if (this.countrySearch === "Without Country") {
              doc = doc.filter(d => d.documentCountries.length === 0);
          } else {
              doc = doc.filter(d => {
                  return d.documentCountries.some(dc => {
                      return dc.country.name.toLowerCase().includes(this.countrySearch.toLowerCase());
                  });
              });
          }
      }
      // FILTER COMPANY NAME
      if (this.companySearch) {
        doc = doc.filter(d => {
          return d.documentCompanies.some(dc => {
            return dc.company.name
              .toLowerCase()
              .includes(this.companySearch.toLowerCase());
          });
        });
      }


      return doc;
    },
    documentApplications() {
      const applications = this.$store.getters.obtainDocumentApplications;
      return applications;
    },

    // for filterApplications ,
    documentApplicationsFilter(){
      const applications = this.$store.getters.obtainDocumentApplications;
      const ApplicationFilterOption = [
        {
          label: 'Application',
          items: applications
        },
        {
          label: null ,
          items: [
              {
                  "applicationTypeId": 'applicationNotSet',
                  "name": "Application not set",
              }
          ]
        }
      ]
      
      return ApplicationFilterOption
    },
    documentSources() {
      return this.$store.getters.obtainDocumentSources;
    },
    //sort
    documentSort(){
      if(this.fromTopic){
        const documentSortTopic =this.$store.getters.obtainDocumentSortFromTopicId; 
        try {
          if(this.selectedTopic.topicId === documentSortTopic.topicId ){
            return documentSortTopic;
          }
          else{
            const documentSortFromTopicId = {
              sortField: null,
              sortOrder: null,
              firstPagValue: null,
              rowsPagValue: null,
              documentSourceType: null,
              applications:null,
              companyName: null,
            }
            return documentSortFromTopicId
          }
        } 
        catch (error) {
          const documentSortFromTopicId = {
              sortField: null,
              sortOrder: null,
              firstPagValue: null,
              rowsPagValue: null,
              documentSourceType: null,
              applications:null,
              companyName: null,
            }
          return documentSortFromTopicId
        }
      }
      else{
        return this.$store.getters.obtainDocumentSort;
      }
    }
  },
  methods: {
    goToHeaderPage(section){
      redirectHelperPage(section)
    },

    async getCountries(){
      try {
        this.loading = true; 
        await this.$store.dispatch('obtainCountries');
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getDocuments() {
      try {
        this.loading = true;
        if(this.userRole != 'Analyst'|| this.fromTopic ){
          if(this.fromTopic){
            await this.$store.dispatch('obtainDocumentsByTopicId',{ topicId:this.selectedTopic.topicId, docTopicSegItemsId: undefined } );
          }
          else{
            await this.$store.dispatch('obtainDocuments',{countryId:null, companyId: null});
          }
        }
        // toast.success('Documents obtained successfully.');
        this.loading = false;
      } catch (error) {
        // toast.error(error.message);
        this.loading = false;
      }
    },
    async getDocumentApplications() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentApplications');
        // toast.success('Document Applications obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getDocumentSources() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentSources');
        // toast.success('Document Sources obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },

    setDocumentSort(){
      try {
        //sort
        this.sortFieldValue = this.documentSort.sortField;
        this.sortOrderValue = this.documentSort.sortOrder;
        

        //pag
        if(this.documentSort.firstPagValue === null ){
          this.firstPagValue = 0 
        }
        else{
          this.firstPagValue = this.documentSort.firstPagValue;
        }
        if(this.documentSort.rowsPagValue === null){
          this.rowsPagValue = 25;
        }
        else{
          this.rowsPagValue = this.documentSort.rowsPagValue
        }
        // applicationFilters
        this.filterDocumentSourceType = this.documentSort.documentSourceType
        this.filterApplication = this.documentSort.applications
        // COUNTRY FILTERS
        this.countrySearch = this.documentSort.countrySearch;
        // companyFilters  
        this.companySearch = this.documentSort.companyName;

      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      if (!date) return '';
      return formatToGlobalDate(date);
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
      this.filterApplication = null;
      this.filterDocumentSourceType = null;
      this.countrySearch = null; 
      this.companySearch = null;
    },
    // openCreateDocument
    openCreateDocumentDialog() {
      if(this.fromTopic){
        this.$store.dispatch('cleanSelectedProgram');   // clean SelectedProgram          
        this.$router.push('./documents/new');
      }
      else{
        this.$store.dispatch('cleanSelectedProgram');   // clean SelectedProgram          
        this.$router.push('./topics/documents/new');
      }
    },
    openEditDocumentDialog(document) {
      if(this.fromTopic){
        this.$router.push(`./documents/edit/${document.documentId}`);
      }
      else{
        this.$router.push(`./topics/documents/edit/${document.documentId}`);
      }      
    },

    // PreviewDialogs 
    openPreviewDocumentDialog(document){
      this.previewDocumentDialog = true;
      this.selectedRowDocument = document;
    },
    hoverPreviewDocumentDialogIn(document){
      this.previewHoverDocumentDialog= true;   
      this.selectedRowDocument = document;
    },
    hoverPreviewDocumentDialogOut(){
      this.previewHoverDocumentDialog= null; 
    },
    openPreviewDocumentContracDialig(document){
      this.previewDocumentContractDialog = true;
      this.selectedRowDocumentContract = document
    },
    hoverPreviewDocumentContractDialogIn(document){
      this.previewHoverDocumentContractDialog= true;   
      this.selectedRowDocumentContract = document;
    },
    hoverPreviewDocumentContractDialogOut(){
      this.previewHoverDocumentContractDialog = null
    },
    openPreviewDocumentFileDialig(document){
      window.open(`${document.filePublicUrl}`, '_blank')
    },
    
    openDeleteMultipleDocumentsDialog(){
      this.deleteMultipleDocumentDialog = true;
    },

    // openAddCocument Modal 
    openAddDocument(){
      this.addDocument = true;
    },
    closeDialog() {
      this.deleteMultipleDocumentDialog = false;
      this.createDocumentDialog = false;
      this.previewDocumentDialog = false;
      this.addDocument = false;

    },
    async deleteDocuments(){
      try {
        this.deleteDocumentIsLoading = true;
        await this.$store.dispatch(
          'removeDocuments', 
          this.deleteMultipleDocuments  
        )
        this.deleteDocumentIsLoading = false;
        // toast.success('Successfully removed.');
        this.deleteMultipleDocuments = []
        this.closeDialog();
      } catch (error) {
        this.deleteDocumentIsLoading = false;
        // toast.error(error.message);
      }
    },
    getApplicationNameById(applications) { 
      const result = this.documentApplications
        .filter((d) => applications.includes(d.applicationTypeId))
        .map((d) => {
          switch (d.name) {
            case 'Forecast - Production' :
              // d.icon = `<i class="pi pi-star-fill" style="font-size: 1.5rem; color: blue;"></i>`;
              // d.icon = `fa-solid fa-industry-windows`;
              // d.prefix = 'fa'
              // d.iconName= 'fa-industry'
              d.prefix = 'fal'
              d.iconName= 'industry-windows'
              break;
            case 'Forecast - Requirement':
              // d.icon = `<i class="pi pi-pencil" style="font-size: 1.5rem; color: black;"></i>`;
              // d.icon = `fa-solid fa-landmark-dome`;
              // d.prefix = 'fa'
              // d.iconName= 'fa-landmark-dome'
              d.prefix = 'fal'
              d.iconName= 'landmark-dome'
              break;
            case 'General Information':
              // d.icon = `<i class="pi pi-sun" style="font-size: 1.5rem; color: #9b188e;"></i>`;
              // d.icon = `fa-solid fa-sun`;
              // d.prefix = 'fa'
              // d.iconName= 'fa-sun'
              // <font-awesome-icon :icon="['fal', 'memo-circle-info']" />
              d.prefix = 'fal'
              d.iconName= 'memo-circle-info'
              break;
            
            case 'Knowledgebase':
              // d.icon = `fa-solid fa-landmark-dome`;
              d.prefix = 'fa'
              d.iconName= 'fa-book'
              break
            case 'Country':
              // d.icon = `<i class="pi pi-flag-fill" style="font-size: 1.5rem; color: green;"></i>`;
              // d.icon = `fa-solid fa-flag`;
              d.prefix= 'fa'
              d.iconName = 'fa-flag'
              break;
            case 'Company':
              // d.icon = `<i class="pi pi-building" style="font-size: 1.5rem; color: red;"></i>`;
              // d.icon = `fa-solid fa-building`;
              d.prefix= 'fa'
              d.iconName = 'fa-building'
              break;
            
            default:
              // d.icon = `<i class="pi pi-check" style="font-size: 1.5rem; color: brown;"></i>`;
              // d.icon = `fa-solid fa-check`;
              d.prefix = 'fa'
              d.iconName= 'fa-check'
              break;
          }
          return d;
        });
      return result;
    },
    
    getProgramsName(programs){
      let programArray=[]
      programs.map(program => {
        if(program.documentProgram){
          programArray.push(program.documentProgram.name) 
        }
      })
      if(programArray.length  >0){
        programArray = programArray.filter((x,i,a)=> a.indexOf(x)=== i)
        return programArray.join(', ') 
      }
      else{
        return '--  '
      }
    },

    // Document contries name for countries Column 
    getDocumentCountriesAbreviation(countries){
      let countriesArray = [] ; 
      countries.map(country => {
        // when render component validate if exits
        if(country.country){
          countriesArray.push(country.country.countryCode)
        }
      })
      if( countriesArray.length > 0 ){
        countriesArray = countriesArray.filter((x,i,a ) => a.indexOf(x)=== i)
        return countriesArray.join(' / ')
      }
      else{
        return '-- '
      }

    },
    // Document Companies name for companies Column 
    getDocumentCompaniesNameAbreviation(companies){
      let companiesArray = [];
      companies.map(company => {
        // when render component validate if exits
        if(company.company){
          //Todo Do Length abreviation 9 letters 

          if(company.company.name.length > 10){
            const abreviationCompany = company.company.name.substring(0, 10) + '..';
            companiesArray.push(abreviationCompany)
          }
          else{
            companiesArray.push(company.company.name)
          }
        }
      })
      if(companiesArray.length > 0){
        companiesArray = companiesArray.filter((x,i,a) => a.indexOf(x) === i)
        return companiesArray.join(', ')
      }
      else{
        return '-- '
      }
    },

    // DocumentSourceTypeName
    getDocumentSourceTypeName(docSourceTypeId){
      const documentSourceType = this.documentSources.filter(item => item.documentSourceId === docSourceTypeId )[0];
      // return documentSourceType.name    
      if(documentSourceType){
        return documentSourceType.name    
      }
    },

    // addDocumentCrawler
    // using apifyWebsiteContentCrawler
    async getDocumentDataApifyWebsiteContentCrawler({response}){

      const responseData =  response.data[0]

      if (this.selectedTopic){
        const newDocument = {
          title: responseData.metadata.title,
          // documentSourceTypeId: 6,
          text: responseData.text,
          documentDate :responseData.crawl.loadedTime,
          documentTopics: [
            {
              topicId: this.selectedTopic.topicId
            }
          ],
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument); 
      }
      else{
        const newDocument = {
          title: responseData.metadata.title,
          // documentSourceTypeId: 6,
          text: responseData.text,
          documentDate : responseData.crawl.loadedTime ,
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument); 
      }

      this.closeDialog();
    },

    // using apifySmartArtcvleExtractor
    async getDocumentDataApifySmartArticleExtractor({response}){
      const responseData = response.data[0] 
      const currentDate = new Date();
      if(this.selectedTopic){
        const newDocument = {
          title: responseData.title,
          // documentSourceTypeId: 6,
          text: responseData.text, 
          documentDate: (responseData.date)? responseData.date : currentDate,
          documentTopics: [
            {
              topicId: this.selectedTopic.topicId
            }
          ],
          websiteUrl : responseData.url
        } 
        await this.createDocument(newDocument)
      }
      else{
        const newDocument = {
          title: responseData.title,
          // documentSourceTypeId: 6,
          text: responseData.text, 
          documentDate: (responseData.date)? responseData.date : currentDate ,
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument)
      }
      this.closeDialog();
    },

    // createDocument with Crawler 
    async createDocument(document){
      try {

        let documentForm = new FormData();

        documentForm.append('title', document.title)
        // documentForm.append('documentSourceTypeId', document.documentSourceTypeId)
        documentForm.append('text', document.text)
        documentForm.append('documentDate', document.documentDate)
        documentForm.append('websiteUrl', document.websiteUrl)

        if(document.documentTopics){
          createFormData(documentForm,'documentTopics' , document.documentTopics)
        }

        const response = await createDocument(documentForm);
        this.openEditDocumentDialog(response.data);

        this.closeDialog();
      } catch (error) {
        console.log(' error', error);
      }
    },

    //sortFunctions
    onSort(e){
      if(this.fromTopic){
        const documentSort = { 
          sortField:  e.sortField,
          sortOrder:  e.sortOrder,
          firstPagValue: e.first,
          rowsPagValue: e.rows,
          topicId : this.selectedTopic.topicId,
          documentSourceType: this.filterDocumentSourceType,
          applications : this.filterApplication,
          countrySearch :this.countrySearch, 
          companyName: this.companySearch,
        }
        this.$store.dispatch('updateDocumentSortFromTopicId', documentSort)
      }
      else{
        const documentSort = { 
          sortField:  e.sortField,
          sortOrder:  e.sortOrder,
          firstPagValue: e.first,
          rowsPagValue: e.rows,
          documentSourceType: this.filterDocumentSourceType,
          applications : this.filterApplication,
          countrySearch :this.countrySearch, 
          companyName: this.companySearch,
        }
        this.$store.dispatch('updateDocumentSort',documentSort)
      }
    },
    
    onChangeFiltersFromTopic(){
      const  filterDocumentSourceTypes  = {
        topicId : this.selectedTopic.topicId,
        documentSourceType : this.filterDocumentSourceType,
        applications : this.filterApplication,
        countrySearch : this.countrySearch, 
        companyName: this.companySearch,
      }

      this.$store.dispatch('updateDocumentSortFromTopicIdFilters', filterDocumentSourceTypes)
    },

    onChangeFilters(){
      const  filterDocumentSourceTypes  = {
        documentSourceType : this.filterDocumentSourceType,
        applications : this.filterApplication,
        countrySearch :this.countrySearch, 
        companyName: this.companySearch,
      }
      this.$store.dispatch('updateDocumentSortFilters', filterDocumentSourceTypes)
    }

    
  },
  watch: {
        async selectedTopic() {
          await this.getDocuments();
        },

        filterDocumentSourceType(){
          if(this.fromTopic){
            this.onChangeFiltersFromTopic()
          }
          else{
            this.onChangeFilters();
          }
        },

        countrySearch(){
          
          if(this.fromTopic){
            this.onChangeFiltersFromTopic()
          }
          else{
            this.onChangeFilters();
          }
        },

        companySearch(){
          if(this.fromTopic){
            this.onChangeFiltersFromTopic()
          }
          else{
            this.onChangeFilters();
          }
        },

        filterApplication(){
          if(this.fromTopic){
            this.onChangeFiltersFromTopic()
          }
          else{
            this.onChangeFilters();
          }
        }
    }
};
</script>

<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}

.modalPreview{
  position:fixed;
  z-index: 1101;
  top:0; 
  left: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  pointer-events: none;
}
.modalContainer{
  height: 50%;
  width: 80%;
  overflow: hidden;
  background-color: white;
  margin: 0.75rem;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}
.modalContainer-Text {
  font-size: 1rem;
}
.document-File{
  display: flex; 
  gap: 0.5rem; 
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.document-File:hover{
  color: red;
}

</style>